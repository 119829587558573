<template>
  <AdminPage />
</template>

<script>
import AdminPage from '@/templates/admin/AppAdminPage'
import isMobile from 'ismobilejs'

export default {
  name: 'TheDashboard',
  components: { AdminPage },
  data() {
    return {
      appDomain: '' ,
      showHeader: false,
      showAside: false,
      sliderIndex: 0,
      pagenationIndex: 0
    }
  },
  computed: {
    showLoadingMask: function () {
      return this.$store.getters.showLoadingMask
    },
    isShopDomain: function () {
      return this.appDomain === 'user'
    },
    isAdminDomain: function () {
      return this.appDomain === 'admin'
    },
  },
  watch: {
    $route: async function(to, from) {
      this.appDomain = this.$router.history.current.meta.appDomain
      this.showHeader = this.$router.history.current.meta.showHeader
      this.showAside = this.$router.history.current.meta.showAside
    },
  },
  mounted: async function () {
    // this.$store.dispatch('reset', initialState)
  },
  methods: {
    $_checkLastAccessDate() {
      const lastAccessDate = this.$store.getters.lastAccessDate
      if (!lastAccessDate) {
        // 初回アクセス時はお知らせを全て既読にする
        const newsIds = this.$store.getters['news/userNews'].map(n => n.newsId)
        this.$store.dispatch('news/setCheckedNewsIds', newsIds)
      }
      this.$store.dispatch('set_last_access_date', new Date())
    },
    /**
     * モバイルのlandscape mode対応
     */ 
    $_setDisplaySizeHandler: function () {
      // 画面のサイズ変動があった時に高さを再計算する
      window.addEventListener('resize', this.$_setFillHeight)
      // 初期化
      this.$_setFillHeight()
      // 画面の縦横が入れ替わった際にサイズを再計算
      window.onorientationchange = function () {
        switch ( window.orientation ) {
          case 0:
            break;
          case 90:
            this.$_setFillHeight()
            break;
          case -90:
            this.$_setFillHeight()
            break;
        }
      }
    },
    /**
     *  SP ブラウザのアドレスバー、ボトムUIの高さを考慮した描画領域の高さを設定
     */
    $_setFillHeight() {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    },
  },
}
</script>
